<template>
  <section class="category">
    <header class="hero-small">
      <div>
        <h1>Sol et revêtements</h1>
      </div>
    </header>
    <main v-if="!isLoading" class="grid-container">
      <app-error v-if="errors.length" title="Erreur" :messages="errors"></app-error>
      <div v-else class="lots-list">
        <div class="lot grid-x" v-for="lot in lots" :key="lot.lotId">
          <div class="lot-image cell medium-4 small-12">
            <img :src="lot.image" :alt="lot.name"/>
          </div>
          <div class="lot-content cell medium-8 small-12">
            <h3>{{ lot.name }}</h3>
            <p>{{ lot.description.replace(/•/g, '➔').replace(/\n */g, '\n') }}</p>
          </div>
        </div>
      </div>
    </main>
  </section>
</template>

<script>
import lotApi from '@/services/api/lot';

const CATEGORY_ID = 'b885a49c-4833-41f8-ac16-f6245fe74ecc';

export default {
  name: 'category-sols-et-revetements',
  components: {
  },
  data() {
    return {
      lots: [],
      errors: [],
      isLoading: true,
    };
  },
  mounted() {
    this.getLots(CATEGORY_ID);
  },
  methods: {
    async getLots(categoryId) {
      try {
        const response = await lotApi.getAll(categoryId);
        this.lots = response;
      } catch (error) {
        this.errors.push('Aucun lot n\'a été trouvé');
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.category
  background-color: $medium-bg
  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/category-hero.png)
  .lots-list
    padding: 96px 128px
    display: flex
    flex-direction: column
    @media (max-width: 768px)
      padding: 32px 24px
    .lot
      display: flex
      padding: 40px 30px
      background: $white
      box-shadow: 0px 2px 12px 0px rgba(196, 201, 202, 0.3)
      border-radius: 8px
      max-width: 1200px
      margin: 0 auto 18px auto
      @media (max-width: 768px)
        flex-direction: column-reverse
      .lot-image
        img
          max-height: 100%
          border-radius: 8px
          @media (max-width: 768px)
            max-width: 100%
            margin-top: 24px
      .lot-content
        padding: 0 0 0 43px
        box-sizing: border-box
        @media (max-width: 768px)
          padding: 0
        h3
          margin-bottom: 24px
          @media (max-width: 768px)
            text-align: center
        p
          white-space: pre-wrap

</style>
